html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* [type=button]{
    -webkit-appearance: none !important;
  } */
